import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import * as React from 'react';
import Seo from '../components/Seo/Seo';
import * as styles from './404.module.scss';

const c = classNames.bind(styles);

const NotFoundPage = () => {

	return (
		<main className={c('wrapper')}>
			<Seo seo={{ title: "404 : Page non trouvée - Levana" }} />
			<section className={c('content')}>
				<h1 className={c('title')}>404</h1>
				<h2 className={c('subtitle')}>Page non trouvée</h2>
				<p className={c('text')}>La page que vous souhaitez consulter n'existe pas.</p>
				<Link to="/" className={c('button')}>Retourner à l'accueil</Link>
			</section>
		</main>
	)
};

export default NotFoundPage;
